import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const CustomerComplete = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <CheckCircleOutlineIcon
        color="success"
        sx={{ fontSize: 100 }}
      />
      <Typography variant="h4" align="center" mt={2}>
        You're all done!
      </Typography>
    </Box>
  );
};

export default CustomerComplete;