import * as React from "react";
import Grid from "@mui/material/Grid";
import CustomerDetailsView from "./CustomerDetailsView";
import OperatorJobViewUserImagesAndVideosComponent from "./OperatorJobViewUserImagesAndVideosComponent";
import FaultCarousel from "./FaultCarousel";
import { Box } from "@mui/material";
import MediaViewerComponent from "./CustomerMediaViewerComponent";

//This will change to Nav etc just using this for Demo

export default function OperatorJobViewComponent(props) {

  function mapFaultsToObjects(user) {
    return user.faults.map((fault) => ({
      imageUrl: fault.uploadedMedia.uploadedUrl,
      type: fault.type,
      description: fault.description,
    }));
  }

  console.log(props);

  return (
    <Grid>
      {props.selectedUser === undefined ? null : (
        <Grid>
          <Box marginBottom={"5px"}>
            <CustomerDetailsView selectedUser={props.selectedUser} />
          </Box>
          <Box>
            <MediaViewerComponent images={mapFaultsToObjects(props.selectedUser)} approve/>
          </Box>
        </Grid>
      )}
    </Grid>
  );
}
