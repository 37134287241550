import * as React from "react";
import Grid from "@mui/material/Grid";
import {
  createTheme,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";

import OperatorRootComponent from "./OperatorRootComponent";
import CustomerRootComponent from "./CustomerRootComponent";

const theme = createTheme({
  palette: {
    background: {
      default: "#eeeeee",
    },
    primary: {
      main: "#d90429",
    },
    secondary: {
      main: "#121926",
      light: "rgba(18, 25, 38, 0.2)", // Set your desired lighter color here
      dark:  "rgba(13, 18, 29, 0.8)",
      contrastText: "white",
    },
  },
});

export default function RouteComponent() {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Check for mobile view
  const [value, setValue] = React.useState("Operator");
  const [mobileDrawerOpen, setMobileDrawerOpen] = React.useState(false);

  const handleNavigation = (option) => {
    setValue(option);
    handleMobileDrawerClose();
  };

  const handleMobileDrawerOpen = () => {
    setMobileDrawerOpen(true);
  };

  const handleMobileDrawerClose = () => {
    setMobileDrawerOpen(false);
  };

  const navigationOptions = ["Operator", "Customer"];

  const drawer = (
    <div>
      <List>
        {navigationOptions.map((option) => (
          <ListItem
            key={option}
            onClick={() => handleNavigation(option)}
            sx={{
              backgroundColor: value === option ? "#f0f0f0" : "transparent", // Highlight the selected option
            }}
          >
            <ListItemText
              primary={option}
              primaryTypographyProps={{
                color: value === option ? "primary" : "initial", // Set text color based on selection
              }}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Grid>
        <AppBar
          position="static"
          sx={{
            backgroundColor: "rgba(13, 18, 29, 1)", // Transparent red color
          }}
        >
          <Toolbar>
            <div
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                marginRight: "20px",
              }}
            >
              <img
                src="./img/LogoDark.png"
                style={{ width: "300px" }}
                alt="Placeholder"
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="h6"
                component="div"
                sx={{
                  cursor: "pointer",
                  display: { xs: "block", sm: "none" },
                }}
                onClick={handleMobileDrawerOpen}
              >
                <MenuIcon /> {/* Use the MenuIcon component */}
              </Typography>
              {isMobile ? ( // Use isMobile to conditionally render menu options
                <Drawer
                  anchor="right"
                  open={mobileDrawerOpen}
                  onClose={handleMobileDrawerClose}
                >
                  {drawer}
                </Drawer>
              ) : (
                // Render menu options in a single Typography for larger screens
                navigationOptions.map((option) => (
                  <Typography
                    key={option}
                    variant="h6"
                    component="div"
                    sx={{
                      cursor: "pointer",
                      marginRight: "20px",
                      color: theme.palette.secondary.contrastText,
                      borderBottom:
                        value === option
                          ? `2px solid ${theme.palette.primary.main}`
                          : "none",
                      paddingBottom: "8px",
                      transition: "border-bottom 0.3s ease-in-out",
                      "&:hover": {
                        color: theme.palette.primary.main,
                      },
                    }}
                    onClick={() => handleNavigation(option)}
                  >
                    {option}
                  </Typography>
                ))
              )}
            </div>
          </Toolbar>
        </AppBar>
        <div style={{ marginTop: "30px" }}>
          {value === "Operator" ? (
            <OperatorRootComponent />
          ) : (
            <CustomerRootComponent />
          )}
        </div>
      </Grid>
    </ThemeProvider>
  );
}
