const cloudName = "dfltqt6qx";
const apiKey = "918386111829823";
const apiSecret = "7Ww2DEd0Mj7UbzWPgAf7OrauW1o";

export async function UploadMediaToCloudinary(mediaItem) {
  const formData = new FormData();
  const response = await fetch(mediaItem.imageUrl); // Fetch the actual file data
  const fileBlob = await response.blob();
  const fileName = mediaItem.description; // Set the file name to the description

  formData.append("file", fileBlob, fileName); // Pass the file name as the third argument
  formData.append("upload_preset", "ml_default");
  // Calculate the date 30 days from now
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + 30);
  formData.append("expires_at", expirationDate.toISOString()); // Convert to ISO 8601 format

  try {
    const response = await fetch(
      `https://api.cloudinary.com/v1_1/${cloudName}/upload`,
      {
        method: "POST",
        body: formData,
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        },
      }
    );

    const data = await response.json();

    console.log(data)

    // Create a new object with Cloudinary URL and other properties
    const uploadedMedia = {
      uploadedUrl: data.secure_url,
      description: mediaItem.description,
      type: mediaItem.type,
    };

    return uploadedMedia

    // You can now save or process uploadedMedia as needed
  } catch (error) {
    console.error("Error uploading media:", error);
  }
}
