import * as React from "react";

import RouteComponent from "./Components/RootComponent";

function App() {
  return (
    <div className="App">
      <RouteComponent></RouteComponent>
    </div>
  );
}

export default App;
