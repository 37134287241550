import * as React from "react";

import Container from "@mui/material/Container";
import CustomerStepperComponent from "./CustomerStepperComponent";
import CustomerComplete from "./CustomerComplete";

//This will change to Nav etc just using this for Demo

export default function CustomerRootComponent(props) {
  const [values, setValues] = React.useState({
    FirstName: "",
    LastName: "",
    Email: "",
    ContactNumber: "",
    Registration: "",
  });

  const [images, setImages] = React.useState([]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const [complete, setComplete] = React.useState(false);
  return (
    <Container sx={{ height: "85vh", minWidth: "100vw" }}>
      {complete === false ? (
        <CustomerStepperComponent
          values={values}
          handleChange={handleChange}
          images={images}
          setImages={setImages}
          complete={complete}
          setComplete={setComplete}
        />
      ) : (
        <CustomerComplete />
      )}
    </Container>
  );
}
