import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";

import CustomerInformationFormComponent from "./CustomerInformationFormComponent";
import CustomerMediaViewerComponent from "./CustomerMediaViewerComponent";
import CustomerSubmissionComponent from "./CustomerSubmissionComponent";
import CustomerFaultDescriptionComponent from "./CustomerFaultDescriptionComponent";
import { UploadMediaToCloudinary } from "../Cloudinary/UploadMediaToCloudinary";
import { CircularProgress, Typography } from "@mui/material";

const steps = ["Customer Information", "Upload Images / Videos", "Submit"];

export default function CustomerStepperComponent(props) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const [loading, setLoading] = React.useState(false);

  const toggleLoading = () => {
    setLoading(!loading);
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const Submit = async () => {
    try {
      toggleLoading(); // Set loading to true

      props.values.UniqueId = props.values.FirstName + props.values.LastName;

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(props.values),
      };
      
      await fetch("https://faultify-api-five.vercel.app/customer", requestOptions).then(
        (response) => response.json()
      );

      const updatedImages = [];

      for (let i = 0; i < props.images.length; i++) {
        const mediaItem = props.images[i];
        mediaItem.customerId = props.values.UniqueId;

        const result = await UploadMediaToCloudinary(mediaItem);
        const uploadedMedia = {
          uploadedUrl: result.uploadedUrl,
          description: mediaItem.description,
          type: mediaItem.type,
        };

        updatedImages.push({
          ...mediaItem,
          uploadedMedia: uploadedMedia,
        });
      }
      const requestOptions2 = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(updatedImages),
      };

      await fetch("https://faultify-api-five.vercel.app/customer/fault", requestOptions2).then(
        (response) => response.json()
      );

      props.setComplete(true);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      toggleLoading(); // Set loading back to false
    }
  };

  function getStepContent(step, values, handleChange) {
    if (loading) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress size={80} thickness={4} />
          <Typography variant="h6" style={{ marginTop: 16 }}>Almost there...</Typography>
        </div>
      );
    }
  
    switch (step) {
      case 0:
        return (
          <CustomerInformationFormComponent
            values={values}
            handleChange={handleChange}
          />
        );
      case 1:
        return (
          <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div>
              <CustomerFaultDescriptionComponent
                values={values}
                handleChange={handleChange}
                images={props.images}
                setImages={props.setImages}
              />
            </div>
            <div style={{ overflowY: 'auto'}}>
              <CustomerMediaViewerComponent
                values={values}
                handleChange={handleChange}
                images={props.images}
                setImages={props.setImages}
              />
            </div>
          </div>
        );
      case 2:
        return (
          <CustomerSubmissionComponent
            values={values}
            handleChange={handleChange}
            images={props.images}
            setImages={props.setImages}
          />
        );
  
      default:
        return null;
    }
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        marginTop: "10px",
      }}
    >
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <React.Fragment>
        <Box
          sx={{
            flex: "1 1 auto",
            overflowY: "auto",
            padding: "10px",
          }}
        >
          {getStepContent(activeStep, props.values, props.handleChange)}
        </Box>
      </React.Fragment>
      <Box sx={{ p: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            disabled={activeStep === 0}
            onClick={handleBack}
          >
            Back
          </Button>
          {activeStep === steps.length - 1 ? (
            <Button
              variant="contained"
              color="primary"
              onClick={Submit}
              disabled={loading} // Disable the button when loading is true
            >
              {loading ? "Submitting..." : "Complete"}
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={handleNext}
              disabled={activeStep === steps.length - 1}
            >
              Next
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
}
