import React, { useState } from "react";
import { useMediaQuery, CardContent } from "@mui/material";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {
  Button,
  CardActionArea,
  CardActions,
  Grid,
  Container,
  Dialog,
} from "@mui/material";

export default function MediaViewerComponent(props) {
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const removeImage = (image) => {
    const updatedImages = props.images.filter((img) => img !== image);
    props.setImages(updatedImages);
  };

  const openFullScreenImage = (image) => {
    setSelectedImage(image);
    setOpenImageDialog(true);
  };

  const closeFullScreenImage = () => {
    setOpenImageDialog(false);
    setSelectedImage(null);
  };

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm")); // Check if the screen is mobile-sized

  return (
    <Container sx={{ padding: "20px 0", overflow: "auto" }}>
      <Grid container spacing={4} sx={{ width: "100%" }}>
        {props.images.length > 0 &&
          props.images.map((image, index) => (
            <Grid item key={index} xs={12} sm={6} md={4} lg={4}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  minWidth: 250,
                }}
              >
                <CardActionArea
                  sx={{
                    padding: "5px",
                    height: "100%",
                    cursor: isMobile ? "auto" : "pointer", // Disable click in mobile view
                  }}
                  onClick={isMobile ? null : () => openFullScreenImage(image)}
                >
                  {image.type.includes("image") ? (
                    <CardMedia
                      component="img"
                      sx={{
                        padding: "5px",
                      }}
                      image={image.imageUrl}
                      title="Image Title"
                    />
                  ) : (
                    <video width="100%" height="100%" controls>
                      <source src={image.imageUrl} type={image.type} />
                    </video>
                  )}
                </CardActionArea>
                <CardContent sx={{ padding: "10px" }}>
                  <Typography>{image.description}</Typography>
                </CardContent>
                <CardActions>
                  {props.approve !== undefined ? (
                    <Button
                      size="small"
                      variant="contained"
                    >
                      Action
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      variant="contained"
                      onClick={() => removeImage(image)}
                    >
                      Delete
                    </Button>
                  )}
                </CardActions>
              </Card>
            </Grid>
          ))}
      </Grid>
      {/* Full Screen Image Dialog */}
      <Dialog
        open={openImageDialog}
        onClose={closeFullScreenImage}
        maxWidth="md"
      >
        {selectedImage && selectedImage.type.includes("image") ? (
          <CardMedia
            component="img"
            sx={{
              maxHeight: "80vh",
              objectFit: "contain",
            }}
            image={selectedImage.imageUrl}
            title="Full Screen Image"
          />
        ) : (
          <video width="100%" controls>
            <source src={selectedImage?.imageUrl} type={selectedImage?.type} />
          </video>
        )}
      </Dialog>
    </Container>
  );
}
