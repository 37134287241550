import React from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";

export default function CustomerDetailsView(props) {
  return (
    <Paper elevation={4} sx={{ padding: "20px", backgroundColor: "#f5f5f5" }}>
      <Typography variant="h5" gutterBottom sx={{ paddingBottom: "10px" }}>
        Information
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} sx={{ border: "1px solid #ccc", padding: "10px" }}>
          <Typography variant="body1">
            <strong>First Name:</strong> {props.selectedUser.FirstName}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ border: "1px solid #ccc", padding: "10px" }}>
          <Typography variant="body1">
            <strong>Last Name:</strong> {props.selectedUser.LastName}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ border: "1px solid #ccc", padding: "10px" }}>
          <Typography variant="body1">
            <strong>Email:</strong> {props.selectedUser.Email}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ border: "1px solid #ccc", padding: "10px" }}>
          <Typography variant="body1">
            <strong>Contact Number:</strong> {props.selectedUser.ContactNumber}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ border: "1px solid #ccc", padding: "10px" }}>
          <Typography variant="body1">
            <strong>Registration:</strong> {props.selectedUser.Registration}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
