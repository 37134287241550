import Grid from "@mui/material/Grid";
import * as React from "react";
import CustomerDetailsView from "./CustomerDetailsView";
import FaultCarousel from "./FaultCarousel";

export default function CustomerSubmissionComponent(props) {
  const selectedUser = props.values;

  return (
      <Grid container spacing={4}>
        {/* CustomerDetailsView taking up full width on small screens */}
        <Grid item xs={12} sm={12} mb={12} lg={6} sx={{ marginBottom: "1px" }}>
          <CustomerDetailsView selectedUser={selectedUser} />
        </Grid>
        {/* FaultCarousel taking up full width on small screens */}
        <Grid
          item
          xs={12}
          sm={12}
          mb={12}
          lg={6}
          sx={{ marginTop: "0px", marginBottom: "0px" }}
        >
          <FaultCarousel images={props.images} />
        </Grid>
      </Grid>
  );
}
