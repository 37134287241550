import * as React from "react";
import { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Drawer,
  IconButton,
  CircularProgress,
  Typography,
} from "@mui/material";
import OperatorJobDataTableComponent from "./OperatorJobDataTableComponent";
import OperatorJobViewComponent from "./OperatorJobViewComponent";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";

export default function OperatorRootComponent(props) {
  const [selectedUser, setSelectedUser] = React.useState(undefined);
  const [customers, setCustomers] = React.useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Added loading state

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  useEffect(() => {
    if (customers.length === 0) {
      setIsLoading(true); // Set loading state to true when fetching data
      GetCustomers();
    }
  }, [customers.length]);

  const GetCustomers = async () => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    var result = await fetch(
      "https://faultify-api-five.vercel.app/customer",
      requestOptions
    ).then((response) => response.json());
    result = JSON.parse(result)
    for (let i = 0; i < result.length; i++) {
      result[i].id = i;
      result[i].faults = [];
    }
    setCustomers(result);
    setIsLoading(false); // Set loading state to false after fetching data
  };

  useEffect(() => {
    async function GetCustomerFaults() {
      if (selectedUser !== undefined) {
        const requestOptions = {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        };

        var result = await fetch(
          "https://faultify-api-five.vercel.app/customer/faults/" + selectedUser.UniqueId,
          requestOptions
        ).then((response) => response.json());
        
        result = JSON.parse(result)
        var userWithFaults = selectedUser;
        userWithFaults.faults = result;
        openDrawer();
      }
    }
    GetCustomerFaults();
  }, [selectedUser]);

  return (
    <>
      {isLoading ? ( // Conditional rendering based on loading state
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <CircularProgress size={80} thickness={4} />
          <Typography variant="h6" style={{ marginTop: 16 }}>
            Almost there...
          </Typography>
        </div>
      ) : (
        <Grid item xs={12} md={6}>
          <OperatorJobDataTableComponent
            rows={customers}
            setSelectedUser={setSelectedUser}
          />
        </Grid>
      )}
      <Drawer
        anchor="bottom"
        open={isDrawerOpen}
        onClose={closeDrawer}
        elevation={16}
      >
        <div style={{ padding: "5px" }}>
          <IconButton onClick={closeDrawer}>
            <ExpandCircleDownIcon />
          </IconButton>
          <div style={{ padding: "10px" }}>
            <OperatorJobViewComponent selectedUser={selectedUser} />
          </div>
        </div>
      </Drawer>
    </>
  );
}
