import React from "react";
import TextField from "@mui/material/TextField";
import { FormControl, FormLabel, FormGroup } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "97%",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: "0px",
    marginBottom: theme.spacing(2),
  },
  formGroup: {
    marginBottom: theme.spacing(2),
  }
}));

function CustomerInformationFormComponent(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <FormControl fullWidth className={classes.formControl}>
        <FormGroup className={`${classes.formGroup} ${classes.focusedField}`}>
          <FormLabel>First Name</FormLabel>
          <TextField
            fullWidth
            variant="outlined"
            value={props.values.FirstName}
            onChange={props.handleChange("FirstName")}
          />
        </FormGroup>
        <FormGroup className={`${classes.formGroup} ${classes.focusedField}`}>
          <FormLabel>Last Name</FormLabel>
          <TextField
            variant="outlined"
            value={props.values.LastName}
            onChange={props.handleChange("LastName")}
          />
        </FormGroup>
        <FormGroup className={`${classes.formGroup} ${classes.focusedField}`}>
          <FormLabel>Email</FormLabel>
          <TextField
            variant="outlined"
            value={props.values.Email}
            onChange={props.handleChange("Email")}
          />
        </FormGroup>
        <FormGroup className={`${classes.formGroup} ${classes.focusedField}`}>
          <FormLabel>Contact Number</FormLabel>
          <TextField
            variant="outlined"
            value={props.values.ContactNumber}
            onChange={props.handleChange("ContactNumber")}
          />
        </FormGroup>
        <FormGroup className={`${classes.formGroup} ${classes.focusedField}`}>
          <FormLabel>Registration</FormLabel>
          <TextField
            variant="outlined"
            value={props.values.Registration}
            onChange={props.handleChange("Registration")}
          />
        </FormGroup>
      </FormControl>
    </div>
  );
}

export default CustomerInformationFormComponent;
