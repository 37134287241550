import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { CardContent, CardMedia, Dialog, useMediaQuery } from "@mui/material";
import { Card, Button, CardActionArea, CardActions } from "@mui/material";
import { useState } from "react";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function FaultCarousel(props) {

  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = props.images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm")); // Check if the screen is mobile-sized

  const openFullScreenImage = (image) => {
    setSelectedImage(image);
    setOpenImageDialog(true);
  };

  const closeFullScreenImage = () => {
    setOpenImageDialog(false);
    setSelectedImage(null);
  };

  return (
    <Paper
      elevation={3}
      sx={{
        backgroundColor: "#f5f5f5",
        maxWidth: 500,
      }}
    >
      <Box
        sx={{
          maxWidth: 500,
          padding: "10px",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Paper
          square
          elevation={0}
          sx={{
            display: "flex",
            alignItems: "center",
            height: 50,
            pl: 2,
            bgcolor: "background.default",
            backgroundColor: "#f5f5f5",
          }}
        >
          <Typography variant="h5">Faults</Typography>
        </Paper>
        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {props.images.length > 0 &&
            props.images.map((image, index) => (
              <Card
                key={index}
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  minWidth: 250,        
                  backgroundColor: "#f5f5f5",
                }}
              >
                <CardActionArea
                  sx={{
                    height: "100%",
                    cursor: isMobile ? "auto" : "pointer", // Disable click in mobile view
                  }}
                  onClick={isMobile ? null : () => openFullScreenImage(image)}
                >
                  {image.type.includes("image") ? (
                    <CardMedia
                      component="img"
                      sx={{
                        padding: "5px",
                      }}
                      image={image.imageUrl}
                      title="Image Title"
                    />
                  ) : (
                    <video width="100%" height="100%" controls>
                      <source src={image.imageUrl} type={image.type} />
                    </video>
                  )}
                </CardActionArea>
                <CardContent sx={{ padding: "10px" }}>
                  <Typography>{image.description}</Typography>
                </CardContent>
              </Card>
            ))}
        </AutoPlaySwipeableViews>
        <MobileStepper
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              Next
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Back
            </Button>
          }
        />
        <Dialog
          open={openImageDialog}
          onClose={closeFullScreenImage}
          maxWidth="md"
        >
          {selectedImage && selectedImage.type.includes("image") ? (
            <CardMedia
              component="img"
              sx={{
                maxHeight: "80vh",
                objectFit: "contain",
              }}
              image={selectedImage.imageUrl}
              title="Full Screen Image"
            />
          ) : (
            <video width="100%" controls>
              <source
                src={selectedImage?.imageUrl}
                type={selectedImage?.type}
              />
            </video>
          )}
        </Dialog>
      </Box>
    </Paper>
  );
}

export default FaultCarousel;
