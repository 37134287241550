import React, { useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      alignItems: "center",
    },
  },
  button: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
      marginLeft: theme.spacing(1),
    },
  },
}));

export default function CustomerFaultDescriptionComponent(props) {
  const classes = useStyles();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [faultDescription, setFaultDescription] = useState("");

  const onImageChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];

        if (file.type.includes("image") && file.size <= 10 * 1024 * 1024) {
          // Handle image file
          props.setImages((prevState) => [
            ...prevState,
            {
              key: props.images.length + i + 1,
              imageUrl: URL.createObjectURL(file),
              type: file.type,
              description: faultDescription,
            },
          ]);
        } else if (file.type.includes("video") && file.size <= 100 * 1024 * 1024) {
          // Handle video file
          props.setImages((prevState) => [
            ...prevState,
            {
              key: props.images.length + i + 1,
              imageUrl: URL.createObjectURL(file),
              type: file.type,
              description: faultDescription,
            },
          ]);
        } else {
          // Display an error message in the Snackbar
          setSnackbarMessage("File exceeds maximum size limit for its type.");
          setOpenSnackbar(true);
        }
        setFaultDescription("");
      }
    }
  };

  const onChange = (event) => {
    setFaultDescription(event.target.value);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "35ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <Box
          sx={{ display: "flex", flexDirection: "row" }}
          pt={1}
          pb={1}
          alignItems={"center"}
        >
          <TextField
            required
            fullWidth
            label="Fault Description "
            multiline
            maxRows={4}
            value={faultDescription}
            onChange={onChange}
          />
          <Button
            className={classes.redOutline}
            variant="contained"
            disabled={faultDescription.length < 1}
            component="label"
          >
            Attach File
            <input
              hidden
              multiple
              type="file"
              onChange={onImageChange}
              className="filetype"
            />
          </Button>
        </Box>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity="error"
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}
