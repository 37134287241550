import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Paper, TableContainer } from "@mui/material";

const columns = [
  { field: "FirstName", headerName: "First name", width: 130 },
  { field: "LastName", headerName: "Last name", width: 130 },
  {
    field: "Email",
    headerName: "Email",
    width: 200,
  },
  {
    field: "ContactNumber",
    headerName: "Contact Number",
    width: 200,
  },
  {
    field: "Registration",
    headerName: "Registration",
    width: 160,
  },
];

export default function OperatorJobDataTable(props) {
  return (
    <Paper sx={{ width: "100%", height: "100%", overflow: "hidden", padding: "15px", marginTop: "-20px"}}>
      <TableContainer>
        <div style={{ height: "84vh", width: "100%" }}>
          <DataGrid
            rows={props.rows}
            columns={columns}
            pageSize={15}
            onRowClick={(event) => {
              props.setSelectedUser(event.row);
            }}
          />
        </div>
      </TableContainer>
    </Paper>
  );
}
